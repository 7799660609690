import React from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const Footer = () => {
  return (
    <>
      <footer>
        <div className="max-w-full mx-auto px-4 sm:px-6 bg-gray-100 border-t border-b py-30 bg-ECOGull ">
          {/* Top area: Blocks */}
          <div className="grid sm:grid-cols-12 gap-5 py-8 md:py-12 border-t border-gray-200 lg:ml-11">
            {/* 1st block */}
            <div className="col-span-12 lg:col-span-4 ">
              <div className="box-border border-b-4 border-blue-900 p-8 bg-gray-200 text-gray-600 text-center rounded-lg xl:w-80 mx-auto bg-EcoGrey">
                <h3 className="font-bold text-4xl mb-4 text-primary ">
                  ECO Bogie
                </h3>
                <div className="text-md font-medium text-primary">
                  <h5>ECO Bogie </h5>
                  <p>Kungsgatan 3</p>
                  <p>38732</p>
                  <p>Borgholm</p>
                  <p>Sweden</p>
                </div>
              </div>
            </div>

            {/* 2nd block */}
            <div className="col-span-6 md:col-span-6 lg:col-span-1 ml-7 mx-auto">
              <h6 className=" text-xl font-bold mb-4">LINKS</h6>
              <ul className="text-md">
                <li className="mb-2">
                  <HashLink
                    to="#"
                    className=" hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out"
                  >
                    About
                  </HashLink>
                </li>
                <li className="mb-2">
                  <HashLink
                    to="#"
                    className=" hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out"
                  >
                    Services
                  </HashLink>
                </li>
                <li className="mb-2">
                  <HashLink
                    to="#"
                    className="hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out"
                  >
                    Contact
                  </HashLink>
                </li>
              </ul>
            </div>

            {/* 3rd block */}
            <div className="col-span-6 md:col-span-6 lg:col-span-4 mx-auto">
              <h6 className=" text-xl font-bold mb-4">OUR SERVICES</h6>
              <ul className="text-md">
                <li className="mb-2">
                  <Link
                    to="#"
                    className=" hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out"
                  >
                    Battery
                  </Link>
                </li>
                <li className="mb-2">
                  <Link
                    to="#"
                    className=" hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out"
                  >
                    Powertrain{" "}
                  </Link>
                </li>
                <li className="mb-2">
                  <Link
                    to="#"
                    className=" hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out"
                  >
                    Complete Bogie solutions
                  </Link>
                </li>
                <li className="mb-2">
                  <Link
                    to="#"
                    className=" hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out"
                  >
                    State of the art control and monitoring{" "}
                  </Link>
                </li>
              </ul>
            </div>

            {/* 4th block */}
            <div className="col-span-12 text-center mx-auto lg:col-span-3 font-bold uppercase text-blue-900">
              <div className="text-xl mb-6">ECO BOGIE</div>

              <div className="text-md font-medium mb-6">
                A Sweden-based company.
              </div>
            </div>
          </div>

          <div className="flex flex-wrap items-center md:justify-between justify-center mx-auto px-4">
            <div className="w-full md:w-4/12 px-4 mx-auto text-center py-2">
              <div className="text-sm text-gray-200 font-semibold py-1">
                Copyright &copy; {new Date().getFullYear()}
                {"  "}
                <HashLink to="#" className=" hover:text-gray-900">
                  ECO Bogie
                </HashLink>
                . All rights reserved.
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
export default Footer;
