import React from "react";
import "./index.css";
import App from "./App";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { Amplify } from "aws-amplify";

import amplifyconfig from "./amplifyconfiguration.json";
import { configureAutoTrack } from "aws-amplify/analytics";

Amplify.configure(amplifyconfig);

configureAutoTrack({
  // REQUIRED, turn on/off the auto tracking

  enable: true,

  // REQUIRED, the event type, it's one of 'event', 'pageView' or 'session'

  type: "session",

  // OPTIONAL, additional options for the tracked event.

  options: {
    // OPTIONAL, the attributes of the event
    attributes: {
      customizableField: "attr",
    },
  },
});
const rootElement = document.getElementById("root");
const root = createRoot(rootElement);
root.render(
  <StrictMode>
    <App />
  </StrictMode>
);
