import React from "react";
import { Link } from "react-router-dom";

const Product = () => {
  return (
    <>
      <section className="text-gray-600 body-font font-lora">
        <div className="my-4 py-4" id="product">
          <h2 className="my-2 text-center text-3xl uppercase font-bold text-primary">
            Products
          </h2>
          <div className="flex justify-center">
            <div className="w-24 border-b-4 border-primary mb-8"></div>
          </div>

          <div className="px-4" data-aos="fade-down" data-aos-delay="600">
            <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-5">
              <div className="bg-ECOGull transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max">
                <div className="m-2 text-justify text-sm">
                  <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-12 text-primary">
                    Battery solutions
                  </h4>
                  <p className="text-md font-medium leading-5 h-auto md:h-48 text-primary">
                    Providing state of the art battery solutions for railway
                    applications. Our batteries comes with BMS (Battery
                    management solutions). The batteries are compliant with IEC
                    62928:2018, EN 62864-1:2016.
                  </p>
                  <div className="flex justify-center my-4">
                    <Link
                      to="/contact"
                      className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl text-primary"
                    >
                      Contact us{" "}
                      <svg
                        className="w-4 h-4 ml-1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </Link>
                  </div>
                </div>
              </div>

              <div className="bg-ECOGull transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3">
                <div className="m-2 text-justify text-sm">
                  <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-12 text-primary">
                    Powertrain
                  </h4>
                  <p className="text-md font-medium leading-5 h-auto md:h-48 text-primary">
                    We provide modern powertrain solutions for Freight wagons.
                  </p>
                  <div className="flex justify-center my-4">
                    <Link
                      to="/contact"
                      className="text-white bg-CosaSiennaClay hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl"
                    >
                      Contact us{" "}
                      <svg
                        className="w-4 h-4 ml-1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </Link>
                  </div>

                  {/* <ul className="border-t border-gray-300 py-8 space-y-6">
                                    <li className="flex items-center space-x-2 px-8">
                                        <span className="bg-blue-600 rounded-full p-1">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3 text-white" viewBox="0 0 20 20" fill="currentColor">
                                                <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"></path>
                                            </svg>
                                        </span>
                                        <span className="text-gray-600 capitalize">Free Setup Guidance</span>
                                    </li>
                                </ul> */}
                </div>
              </div>

              <div className="bg-ECOGull transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3">
                <div className="m-2 text-justify text-sm">
                  <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-12 text-primary">
                    Complete Bogie solutions
                  </h4>
                  <p className="text-md font-medium leading-5 h-auto md:h-48 text-primary">
                    Providing turn key solutions to exchange your non powered
                    Y25 Bogie with a complete Y25 ECO-Bogie.
                  </p>
                  <div className="flex justify-center my-4">
                    <Link
                      to="/contact"
                      className="text-white bg-CosaSiennaClay hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl"
                    >
                      Contact us{" "}
                      <svg
                        className="w-4 h-4 ml-1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </Link>
                  </div>
                </div>
              </div>

              <div className="bg-ECOGull transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 text-primary">
                <div className="m-2 text-justify text-sm">
                  <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-12 text-primary">
                    Signal and control{" "}
                  </h4>
                  <p className="text-md font-medium leading-5 h-auto md:h-48 text-primary">
                    The Y25 ECO-Bogie needs to communicate with each other. The
                    communication is robust and provide a fail-safe solutions
                    between the wagons.Providing stable radio signals and
                    control solutions for critical networks
                  </p>
                  <div className="flex justify-center my-4">
                    <Link
                      to="/cpntact"
                      className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl text-primary"
                    >
                      Contact us{" "}
                      <svg
                        className="w-4 h-4 ml-1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Product;
