import React from "react";
import img from "../images/Web-developer.svg";
import ecologo from "../images/ECOwithLoco.png";
import { Link } from "react-router-dom";

const Intro = () => {
  return (
    <>
      <div className="m-auto max-w-8xl p-2 md:p-12 h-5/6" id="about">
        <div className="font-jost fmb-5 md:text-4xl text-2xl font-bold text-primary text-center">
          BENEFITS{" "}
        </div>
        <div
          className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left"
          data-aos="fade-up"
        >
          <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center max-w-full max-h-full">
            <img
              alt="card img"
              className="rounded-t float-right h-auto"
              src={ecologo}
              style={{ borderRadius: "20px" }} // Adjust the radius as needed
            />
          </div>

          <div
            className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8"
            data-aos="zoom-in"
            data-aos-delay="500"
          >
            <li className="text-3xl  text-primary font-Roboto">
              Reduced locomotive needs
            </li>
            <li className="text-3xl  text-primary font-Roboto">
              Providing better acceleration and traction
            </li>
            <li className="text-3xl  text-primary font-Roboto">
              Longer trainsets
            </li>
            <li className="text-3xl  text-primary  font-Roboto">
              Solid robust and fail-safe internal communication
            </li>
            <li className="text-3xl  text-primary font-Roboto">
              Minimized slack and drag
            </li>
            <li className="text-3xl  text-primary  font-Roboto">
              Effective and regenerative braking
            </li>
            <li className="text-3xl  text-primary  font-Roboto">
              State of the art train set with automatic control engineering
            </li>
            <li className="text-3xl  text-primary font-Roboto">
              Using AI and algorithms for continuous improvements
            </li>
            <li className="text-3xl  text-primary font-Roboto">
              Connected wagons, providing endless possibilities. Such as Fleet
              management and predictive maintenance
            </li>
          </div>
        </div>
      </div>
    </>
  );
};

export default Intro;
