import React from "react";
import Moreinfo from "../components/Moreinfo";
import Cta from "../components/Cta";
import Footer from "../components/Footer";
import Hero from "../components/Hero";
import Intro from "../components/Intro";
import Product from "../components/Product";
import Services from "../components/Services";

const Home = () => {
  return (
    <>
      <div className=" bg-EcoGrey">
        <Hero />
        <Intro />
        <Services />
        <Product />

        <Cta />
        <Footer />
      </div>
    </>
  );
};

export default Home;
